import React from 'react'
import logo from '../assets/logo.png'
import { Navbar, Container } from 'react-bootstrap'
import Video from './Video'
import AudioPlayer from './AudioPlayer'

export default function Header() {
  return (
    <header>
      <Navbar className='absolute-nav' expand={false}>
        <Container fluid>
          <Navbar.Brand href='#home'>
            <img src={logo} className='img-fluid logo-img' alt='logo' />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Video />
      <AudioPlayer />
    </header>
  )
}
