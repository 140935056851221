import soundtrackMp3 from '../assets/soundtrack.mp3'
import soundtrackOgg from '../assets/soundtrack.ogg'

import React, { useRef, useEffect } from 'react'

function AudioPlayer() {
  const audioRef = useRef(null)

  const playAudio = () => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.volume = 0.3
      audioRef.current.play()
      document.body.removeEventListener('click', playAudio)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', playAudio)

    return () => {
      document.body.removeEventListener('click', playAudio)
    }
  }, [])

  return (
    <div>
      <audio ref={audioRef} controls className='audio'>
        <source src={soundtrackMp3} type='audio/mpeg' />
        <source src={soundtrackOgg} type='audio/ogg' />
        Il tuo browser non supporta l'elemento audio.
      </audio>
    </div>
  )
}

export default AudioPlayer
